h1 {
    font-weight: 100;
}

li {
    font-weight: 400;
}
.background-firebric-light {
    background-color: rgba(142,157,32,.3);
}

.background-firebric-dark {
    background-color: rgba(142,157,32,.5);
}

/**
*   These are the "original" values. Saving here for reference
*   after changing to the values above
*/
.background-firebric-orig-light {
    background-color: rgba(120,180,150,.3);
}

.background-firebric-orig-dark {
    background-color: rgba(120,180,150,.7);
}


#navbar ul {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #aaa;
}

#navbar li {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 1vh 0.4vw;
    text-decoration: none;
    font-size: 0.8vw;
}

#navbar li:hover {
    background-color: #111;
}

.section {
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 1vw;
    min-height: 100vh;
}

#left, #right {
    height: 100%;
}

.holder {
    display: table;
    height: 100%;
}
.innerDiv{
    display: table-cell;
    vertical-align: middle;
}

.icon {
    float: right;
    margin-left: 0.4em;
    height: 3.5vh;
}

.textPane {
    display: table;
    padding: 1%;
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
}

.quarterPane {
    padding-left: 10%;
    padding-right: 10%;
}

.fourSectionPane {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.sectionImage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow:inset 0 0 0 2000px rgba(255,255,255,0.3);
    height: inherit;
}
#aboutImage {
    background-image: url("/resources/images/photo-of-people-doing-fist-bump-3184430_lowres.jpg");
}

#dnaImage {
    background-image: url("/resources/images/dna-1903319_1280.jpg");
}
#dnaImage2 {
    background-image: url("/resources/images/dna-4478127_1280.jpg");
}

#educationImage {
    background-image: url("/resources/images/pile-of-books-159866.jpg");
}

#valueImage {
    background-image: url("/resources/images/closeup-photo-of-black-and-green-keyboard-1194713.jpg");
}

#contactImage {
    background-image: url("/resources/images/megaphone-2335573_1280_green.jpg");
}

.topic {
    font-size: 4vw;
}

#topSection {
    height: 100vh;
}

#logoimg {
    padding-left: 1vh;
    padding-top: 1vh;
    height: 7vh;
}

#socialmedia {
    padding: 1vh;
}

#logosection {
    height: 10vh;
    background-color: light;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

#footer {
    height: 5vw;
    line-height: 5vw;
    text-align: center;
    margin: unset;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    font-size: 1.5vh;
}


#footerContent {
    line-height: 5vw;
    margin: unset;
    font-size: 4vh;
}

#topImageHolder {
    margin: auto;
    height: 85vh;
    text-align: center;
}

#topImage {
    width: 100%;
    height: auto;
    max-width: 630px;
}

.rounded-corners {
    border-radius: 25px;
    border: 4px solid rgba(142,157,32,.5);
    padding: 20px;
    max-width: 95%;
 }


@media only screen and (max-width: 1400px) {
    .fourSectionPane {
        width: auto;
        grid-template-columns: 100%;
        font-size: 2.4vh;
    }
    .topic {
	    font-size: 4vh;
    }
    .section {
        font-size: 2vw;
        height: unset;
        min-height: 75vh;
    }
    #topImage {
        width: 100%;
        height: auto;
        max-width: 50vw;
        max-height: 60vh;
    }
    #navbar li {

    font-size: 3vw;
    }


    #footer {
        height: 10vw;
        line-height: 10vw;
        text-align: center;
        margin: unset;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        font-size: 3vw;
    }
}

@media only screen and (max-width: 768px) {
    .section {
        width: 100%;
        height: 100%;
        grid-template-columns: 100%;
        font-size: 4vw;
        min-height: 50vh;
    }
    p {
	    font-size: 4vw;
    }
    .topic {
	    font-size:7vw;
    }
    .sectionImage {
        height: 90vh;
    }
    .fourSectionPane {
	    font-size: 3vw;
    }
    #topImage {
        width: 100%;
        height: auto;
        max-width: 50vw;
        max-height: 60vh;
    }
     #navbar li {

        font-size: 3vw;
    }
    #footer {
        line-height: 10vw;
        text-align: center;
        margin: unset;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        font-size: 3vw;
    }
}
