body {
    margin: 0px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
}
#main {
}

@media only screen and (max-width: 600px) {
    #main {
	width: 100vw;
	grid-template-columns: auto;
    }
}
